import axios from 'axios';
import { Message,Loading } from 'element-ui';
import qs from 'qs';
import router from '../router';
import $store from '../store/store';
import config from './config.js';
// const loading = null;
export default function $axios(options) {
  // if (options.url==`/parameter/import`) {
  //   loading = Loading.service({
  //     lock: true,
  //     text: 'Loading',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  // });
  // }
  return new Promise((resolve, reject) => {
    let instance = axios.create({
      headers: config.headers,
      withCredentials: config.withCredentials,
      crossDomain: config.crossDomain,
    })
  
    if (process.env.NODE_ENV !== 'development') {
      instance.defaults.baseURL = config.baseUrl;
    } else {
      instance.defaults.baseURL = config.baseUrl;
    }

    // request 拦截器
    instance.interceptors.request.use(
      config => {
        config.cancelToken = new axios.CancelToken(cancel => {
          $store.commit('addCancelToken',cancel)
         }) 
        let url = config.url;
        let token = $store.state.token;  
        if (!token && token.length == 0) {
          token=window.localStorage.getItem("token")
        }  //会导致界面每次打开不回退到主页
        if (url.match("auth")) {
          token = ""
        } else {
          // console.log(token);
          if (token && token.length > 0) {
          config.headers["Authorization"] = token;
          }
          if (config.method === 'post') {
            if (config.url === 'login') {
              config.data = qs.stringify(config.data);
            }
          }
        }
        return config
      },
      error => {
        // 1. 判断请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          // return service.request(originalRequest);// 再重复请求一次
        }
        // 2. 需要重定向到错误页面
        const errorInfo = error.response
        if (errorInfo) {
          error = errorInfo.data // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          const errorStatus = errorInfo.status; // 404 403 500 ...
          router.push({
            path: `/error/${errorStatus}`
          })
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )

    // response 拦截器
    instance.interceptors.response.use(
      response => {
      //   console.log(loading);
      //  if (loading) {
      //   loading.close();
      //  }
        let data;
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (response.data == undefined) {
          data = JSON.parse(response.request.responseText)
        } else {
          data = response.data
        }
        switch (response.data.code) {
          case 200:
            break;
          case 431:
            // Message({
            //   type: "error",
            //   message: response.data.msg
            // });
            // router.push(`/iot-${window.localStorage.getItem('path')}`);
            $store.commit('setToken', "")
            window.localStorage.removeItem('token')
          if (!$store.state.path) {
            router.push(`/iot-lz`);
          } else {
            router.push(`/iot-${$store.state.path}`);
          }
            break
          case 437:
            Message({
              type: "error",
              message: response.data.msg
            });
            // router.push(`/iot-${window.localStorage.getItem('path')}`);
            // router.push(`/iot-${$store.state.path}`);
            break
          case 456:
            // router.push(`/404`);
            break
          default:
            Message({
              type: "error",
              message: response.data.msg
            });
        }
        return data
      },
      err => {
        // if (loading) {
        //   loading.close();
        //  }
        if (err && err.response) {
          console.log(err,err.response);
          switch (err.response.status) {
            case 400:
              err.message = '请求错误'
              break
            case 401:
              err.message = '未授权，请登录'
              break
            case 403:
              err.message = '拒绝访问'
              break
            case 404:
              err.message = `请求地址出错: ${err.response.config.url}`
              break
            case 408:
              err.message = '请求超时'
              break
            case 500:
              err.message = '服务器繁忙，请稍后.....'
              break
            case 501:
              err.message = '服务未实现'
              break
            case 502:
              err.message = '网络异常'
              break
            case 503:
              err.message = '服务不可用'
              break
            case 504:
              err.message = '网关超时'
              break
            case 505:
              err.message = 'HTTP版本不受支持'
              break
            default:
              err.message = '预期之外的错误'
          }
          Message({
            type: "error",
            message: err.response.data.message
          });
        }
        return Promise.reject(err) // 返回接口返回的错误信息
      }
    )

    // 请求处理
    instance(options).then(res => {
      resolve(res)
      return false
    }).catch(error => {
      reject(error)
    })
  })
}
